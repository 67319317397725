import { Component, DoCheck, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'language-switcher',
    templateUrl: './languageSwitcher.html',
    styleUrls: ['./languageSwitcher.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LanguageSwitcher {
    @Input()
    public sidebarCollapsed: boolean = false;

    public options: Array<string>;

    public languageLabels = {
        "en-US": 'EN',
        "sv-SE": 'SV',
        "nb-NO": 'NO',
        "da-DK": 'DA',
        "fi-FI": 'FI',
    };

    public selected: string;

    constructor(private translate: TranslateService) {
        this.selected = translate.currentLang;
        this.options = translate.getLangs();
    }

    onChange(): void {
        this.translate.use(this.selected);
    }
}
