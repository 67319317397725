import { Component, Input, Output, forwardRef, EventEmitter, SimpleChanges, HostBinding, OnChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from "@angular/forms";
import * as moment from 'moment';

@Component({
    selector: 'nc-select-box',
    templateUrl: './ncSelectBox.html',
    styleUrls: ['./ncSelectBox.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'nc-select-box'
    },
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NcSelectBox), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => NcSelectBox), multi: true }
    ]
})
export class NcSelectBox implements ControlValueAccessor, OnChanges {

    @Input() label: string;
    @Input() options: string;
    @Input() idField: string = 'id';
    @Input() nameField: string = 'name';

    @HostBinding('class.disabled') disabledClass = true;

    selectedOption: any = {};

    propagateChange:any = () => {};
    validateFn:any = () => {};
    onTouchedCallback:any = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        this.disabledClass = !changes.options;
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    validate(control) {
        return this.validateFn(control);
    }

    writeValue(value: any) {
        this.selectedOption = value;
    }

    handleSelect(item) {
        this.selectedOption = item;
        this.propagateChange(this.selectedOption);
    }

    isSelected(option) {
        if(this.selectedOption) {
            return option[this.idField] == this.selectedOption[this.idField];
        } else {
            return false;
        }
    }

}
