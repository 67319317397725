import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from "../functions/index";

@Pipe({
    name: 'ncNumber'
})
export class NumberPipe implements PipeTransform {

    transform(number: any, decimals: number = 0): string {
        let validNumber = toNumber(number, decimals) || 0;
        return validNumber.toLocaleString();
    }

}