import { Component, Input, Output, forwardRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from "@angular/forms";
import { startWith, map } from 'rxjs/operators';

@Component({
    selector: 'nc-multi-search',
    templateUrl: './ncMultiSearch.html',
    host: {
        class: 'nc-multi-search'
    },
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NcMultiSearch), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => NcMultiSearch), multi: true }
    ]
})
export class NcMultiSearch implements ControlValueAccessor, OnChanges {

    @Input() placeholder: string;
    @Input() options: any[];

    selectedOptions: any[];
    filteredOptions: any;
    searchCtrl: FormControl;

    propagateChange:any = () => {};
    validateFn:any = () => {};
    onTouchedCallback:any = () => {};

    constructor() {
        this.searchCtrl = new FormControl();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.options) {
            this.filteredOptions = this.searchCtrl.valueChanges.pipe(
                startWith(null),
                map((query) => this.filterOptions(query)),
            );
        }
    }

    filterOptions(query: string) {
        if(this.options && query && typeof query === 'string') {
            return this.options.filter((option) => new RegExp(`${query}`, 'gi').test(option.name));
        } else {
            return this.options;
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    validate(control) {
        return this.validateFn(control);
    }

    writeValue(value: any) {
        this.selectedOptions = value;
    }

    handleSelect(option) {
        if(!option.source.selected) {
            return;
        }
        if(this.selectedOptions instanceof Array == false) {
            this.selectedOptions = [];
        }
        let alreadyExists = this.selectedOptions.find((item) => {
            return item.id == option.source.value.id;
        });
        if(!alreadyExists) {
            this.selectedOptions.push(option.source.value);
            this.propagateChange(this.selectedOptions);
            this.onTouchedCallback();
        }
        this.searchCtrl.setValue(undefined);
    }

    handleRemove(index) {
        this.selectedOptions.splice(index, 1);
        this.propagateChange(this.selectedOptions);
        this.onTouchedCallback();
    }

    handleBlur(event) {
        if(event.relatedTarget && event.relatedTarget.classList.contains('mat-option')) {
            return;
        }
        this.onTouchedCallback();
    }

}
