export function objectToFormData(object: {}, predefinedFormData?: FormData, namespace?: string) {
    let formData = predefinedFormData || new FormData();
    let formKey;
    for(let key in object) {
        if(object.hasOwnProperty(key)) {
            if(namespace) {
                formKey = namespace + '[' + key + ']';
            } else {
                formKey = key;
            }
            if(typeof object[key] === 'object' && !(object[key] instanceof File)) {
                objectToFormData(object[key], formData, key);
            } else {
                formData.append(formKey, object[key]);
            }
        }
    }
    return formData;
}