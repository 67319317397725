import { Component, Input, forwardRef, ViewChild, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from "@angular/forms";

@Component({
    selector: 'nc-file',
    templateUrl: './ncFile.html',
    styleUrls: ['./ncFile.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NcFile), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => NcFile), multi: true }
    ]
})
export class NcFile implements ControlValueAccessor {

    @Input() formats: string;
    @Input() icon: string;

    dragIsHappening = false;

    @HostListener('window:dragover', ['$event'])
    dragOver(event: DragEvent) {
        const element = event.target as HTMLElement;
        if (element.className.includes('cdk-overlay-backdrop') ||
            element.className.includes('drop-area-hidden')
        ) {
            this.dragIsHappening = true;
        }
    }

    @HostListener('window:dragleave', ['$event'])
    dragLeave(event: DragEvent) {
        const element = event.target as HTMLElement;
        if (element.className.includes('drop-area-hidden')) {
            this.dragIsHappening = false;
            return false;
        }
    }

    file: File;

    propagateChange: any = () => { };
    validateFn: any = () => { };
    onTouchedCallback: any = () => { };

    @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
    @ViewChild('input', { static: true }) input: ElementRef;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    validate(control) {
        return this.validateFn(control);
    }

    // Get ngModel value
    writeValue(value: any) { }

    // Set ngModel value
    handleInputChange(event) {
        let file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        if (file) {
            this.file = file;
            this.propagateChange(file);
        }
        this.onTouchedCallback();
    }

    handleDrop(event) {
        this.dragIsHappening = false;
        event.preventDefault();
        this.handleInputChange(event);
        this.wrapper.nativeElement.classList.remove('dragover');
    }

    handleDragEnter(event) {
        // event.dataTransfer.items[0].type
        this.wrapper.nativeElement.classList.add('dragover');
    }

    handleDragLeave(event) {
        this.wrapper.nativeElement.classList.remove('dragover');
    }

    handleClick(event) {
        this.input.nativeElement.click();
    }

}
