import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { IAddress } from '../interfaces';

@Component({
    selector: 'nc-auto-complete',
    template: `
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <mat-label translate>addresses.quick_select</mat-label>
                <input type="text"
                       placeholder="{{'addresses.pick_one' | translate}}"
                       matInput
                       [formControl]="control"
                       [matAutocomplete]="auto"
                       (click)="handleClick()">
                <mat-spinner *ngIf="loading" matSuffix [diameter]="18" ></mat-spinner>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                    <mat-option *ngFor="let option of filteredOptions" [value]="option">
                        {{ getDisplayName(option) }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>`,
})
export class NcAutoComplete implements OnInit {

    @Input()
    control: FormControl;

    @Input()
    options: IAddress[];

    @Input()
    loading: boolean;

    filteredOptions: IAddress[];

    ngOnInit() {
        this.control.valueChanges.pipe(
            startWith(''),
            map(value => {
                this.filteredOptions = this._filter(value.company || value || '');
            })
        ).subscribe();
    }

    handleClick() {
        this.filteredOptions = this._filter(this.control.value);
    }

    displayFn(address: IAddress): string {
        return address && address.company ? this.getDisplayName(address) : '';
    }

    getDisplayName(address: IAddress): string {
        return address.company + ', ' + address.city;
    }

    private _filter(value: string) : IAddress[] {
        const filterValue = value.toLowerCase();

        if (!this.options) {
           return [];
        }

        return this.options.filter(option => this.getDisplayName(option).toLowerCase().includes(filterValue));
    }
}
