import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

interface DatePickerValue { year: number; month: number; day: number; }
interface TimePickerValue { hour: number; minute: number; second: number; }

/**
 * Date time picker that supports week numbers for the scandinavian market.
 */
@Component({
    selector: 'nc-datetime-picker-sc',
    templateUrl: './dateTimePickerSC.html',
    styles: [`
        .confirm-button {
            width: 100%;
            padding: 12px;
        }
        .ngb-dp-week-number {
            &.small {
                font-size: 0.7em !important;
            }
        }
    `],
})
export class DateTimePickerSC implements OnChanges {

    readonly dateFormat: string = 'YYYY-MM-DD';
    readonly dateTimeFormat: string = 'YYYY-MM-DD HH:mm';

    @Input() value: string;
    @Input() minDate: string;
    @Input() maxDate: string;
    @Input() showWeekNumbers: boolean = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    datePickerValue: DatePickerValue;
    datePickerMinDate: DatePickerValue;
    datePickerMaxDate: DatePickerValue;

    timePickerValue: TimePickerValue;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            const previousDateTime = moment(changes.value.currentValue, this.dateTimeFormat);

            if (previousDateTime.isValid()) {
                this.datePickerValue = {
                    year: previousDateTime.year(),
                    month: previousDateTime.month() + 1,
                    day: previousDateTime.date(),
                };
                this.timePickerValue = {
                    hour: previousDateTime.hour(),
                    minute: previousDateTime.minute(),
                    second: 0,
                };
            } else {
                this.datePickerValue = {
                    year: moment().year(),
                    month: moment().month() + 1,
                    day: moment().date(),
                };
                this.timePickerValue = {
                    hour: moment().hour(),
                    minute: moment().minute(),
                    second: 0,
                };
            }
        }

        if (changes.minDate) {
            const minDate = moment(changes.minDate.currentValue, this.dateFormat);

            this.datePickerMinDate = {
                year: minDate.year(),
                month: minDate.month() + 1,
                day: minDate.date(),
            };
        }

        if (changes.maxDate) {
            const maxDate = moment(changes.maxDate.currentValue, this.dateFormat);

            this.datePickerMaxDate = {
                year: maxDate.year(),
                month: maxDate.month() + 1,
                day: maxDate.date(),
            };
        }
    }

    handleConfirmClick() {
        const nextDateTime = moment();

        nextDateTime.year(this.datePickerValue.year);
        nextDateTime.month(this.datePickerValue.month - 1);
        nextDateTime.date(this.datePickerValue.day);
        nextDateTime.hour(this.timePickerValue.hour);
        nextDateTime.minute(this.timePickerValue.minute);

        this.onChange.emit(nextDateTime);
    }
}
