import { Component, Input, Output, forwardRef, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from "@angular/forms";
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { featureToggle } from "../../functions/featureToggle";

@Component({
    selector: 'nc-date-time',
    templateUrl: './ncDateTime.html',
    styles: [`
        .mat-mdc-icon-button.mat-mdc-button-base {
            padding: 10px;
            --mdc-icon-button-state-layer-size: 35px;
        }
    `],
    host: {
        class: 'nc-date-time'
    },
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NcDateTime), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => NcDateTime), multi: true }
    ]
})
export class NcDateTime implements ControlValueAccessor {

    readonly dateTimeFormat: string = 'YYYY-MM-DD HH:mm';

    @Input() placeholder: string;
    @Input() minDate: string;
    @Input() maxDate: string;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

    dateTime: moment.Moment;
    dateTimeString: string = '';
    disabled: boolean;

    propagateChange:any = () => {};
    validateFn:any = () => {};
    onTouchedCallback:any = () => {};

    featureToggle = featureToggle;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    validate(control) {
        return this.validateFn(control);
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        if(value) {
            let dateTime = moment(value);
            if(dateTime.isValid()) {
                this.dateTime = dateTime;
                this.dateTimeString = dateTime.format(this.dateTimeFormat);
            }
        } else {
            this.dateTime = undefined;
            this.dateTimeString = '';
        }
    }

    setValue(value) {
        let dateTime = moment(value, this.dateTimeFormat);
        if(!dateTime.isSame(this.dateTime)) {
            if(dateTime.isValid()) {
                this.dateTime = dateTime;
                this.dateTimeString = dateTime.format(this.dateTimeFormat);
                this.propagateChange(this.dateTime.toISOString());
            } else {
                this.dateTime = undefined;
                this.dateTimeString = '';
                this.propagateChange(undefined);
            }
            this.onChange.emit();
        }
        this.onTouchedCallback();
    }

    handleChange(event) {
        this.setValue(event.value.format(this.dateTimeFormat));
    }

    handleChangeSC(event) {
        this.setValue(event.format(this.dateTimeFormat));
        this.menuTrigger.closeMenu();
    }

    handleBlur(event) {
        this.setValue(event.target.value);
    }

    handleMenuClick(event: MouseEvent) {
        event.stopPropagation();
    }
}
