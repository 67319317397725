import { Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";

@Component({
    selector: 'nc-zoom-image',
    template: `
        <div [hidden]="mousemove" class="image-wrapper">
            <img class="image" [src]="smallImage" alt="">
        </div>
        <img class="zoom-image" [src]="largeImage" alt="" #zoomImage [ngStyle]="zoomStyle">
    `,
    styles: [`
        :host {
            display: block;
            cursor: zoom-in;
            position: relative;
            -webkit-transform: translate3d(0, 0, 0);
            background: linear-gradient(-45deg, #f7f7f7 25%,
                transparent 25%, transparent 50%, #f7f7f7 50%, #f7f7f7 75%, transparent 75%, transparent);
            background-size: 6px 6px;
            overflow: hidden;
        }

        .image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
        }

        .image {
            max-height: 100%;
            max-width: 100%;
            pointer-events: none;
        }

        .zoom-image {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.2s;
            background: #fff;
        }
    `]
})
export class ncZoomImage {
    @ViewChild("zoomImage", { static: true }) zoomImageRef: ElementRef;

    private el: HTMLElement;
    public zoomStyle: any = {};
    public mousemove: boolean;

    @Input() smallImage: string;
    @Input() largeImage: string;

    @HostListener('mousemove', ['$event'])
    private onMousemove(event: MouseEvent) {
        let x: number = event.offsetX;
        let y: number = event.offsetY;
        let zel = this.zoomImageRef.nativeElement;
        let el = this.el;

        // if (zel.offsetWidth < el.offsetWidth && zel.offsetWidth <= tel.offsetWidth) {
        //     return;
        // }

        // el.style.width = zel.offsetWidth + 'px';

        this.mousemove = true;

        this.zoomStyle.left = (el.offsetWidth - zel.offsetWidth) * (x / el.offsetWidth)  + 'px';
        this.zoomStyle.top = (el.offsetHeight - zel.offsetHeight) * (y / el.offsetHeight)  + 'px';
        this.zoomStyle.opacity = 1;
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseleave(event: MouseEvent) {
        this.zoomStyle.opacity = 0;
        this.mousemove = false;
    }

    constructor(private _elementRef: ElementRef) {
        this.el = this._elementRef.nativeElement;
    }

}