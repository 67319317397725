import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from "@angular/forms";

@Component({
    selector: 'nc-multi-string',
    templateUrl: './ncMultiString.html',
    host: {
        class: 'nc-multi-string'
    },
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NcMultiString), multi: true },
    ]
})
export class NcMultiString implements ControlValueAccessor {

    @Input() placeholder: string;
    @Input() hint: string;

    value: string = '';
    isDisabled: boolean = false;

    onChange: (_: any) => void = () => {};
    onTouched: () => void = () => {};

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: string[]): void {
        if(value instanceof Array) {
            this.value = value
                .map(item => item.trim())
                .filter(item => !!item)
                .join(', ');
        }
    }

    handleChange(value: string): void {
        let newValue = value
            .split(',')
            .map(item => item.trim())
            .filter(item => !!item);
        this.onChange(newValue);
    }

    handleFocus(): void {
        this.onTouched();
    }

}
