import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {

    transform(value: any[], separator: string = ', ') {
        if(value instanceof Array) {
            return value.filter(item => !!item).join(separator);
        } else if(typeof value === 'object') {
            return Object.keys(value).map(key => value[key]).filter(item => !!item).join(separator);
        } else {
            return value;
        }
    }

}