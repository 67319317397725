import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    { path: '**', redirectTo: 'pages' }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    useHash: true,
});
