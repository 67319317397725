import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Loader } from "./loader";

@Component({
    selector: 'loader',
    templateUrl: './loader.html',
    styleUrls: ['./loader.scss'],
    encapsulation: ViewEncapsulation.None,
    preserveWhitespaces: true,
})
export class LoaderComponent {
    @Input() loader: Loader;
}
