<mat-form-field>
    <mat-label>{{placeholder}}</mat-label>
    <input
        matInput
        [disabled]="isDisabled"
        [ngModel]="value"
        (ngModelChange)="handleChange($event)"
        (focus)="handleFocus()" />
    <mat-hint align="start">{{ hint }}</mat-hint>
</mat-form-field>
