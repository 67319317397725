import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { FormControl } from "@angular/forms";

@Component({
    selector: 'nc-select',
    template: `
        <div class="form-group">
            <mat-form-field>
                <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
                <mat-select [formControl]="control">
                    <mat-option *ngFor="let item of _options" [value]="item">{{ getLabel(item) }}</mat-option>
                </mat-select>
                <mat-error><error-message [forControl]="control"></error-message></mat-error>
            </mat-form-field>
        </div>`,
})
export class NcSelect implements OnInit, OnChanges {

    @Input()
    control: FormControl;

    @Input()
    options: any[];

    @Input()
    label: any;

    @Input()
    placeholder: string;

    @Input()
    trackObjectsBy: string = 'id';

    _options: any[] = [];

    getLabel(item): string {
        if (typeof item === 'string') {
            return item;
        }

        if (!this.label) {
            return item.name;
        }

        if (typeof this.label === 'function') {
            return this.label(item);
        }

        if (typeof this.label === 'string') {
            return item[this.label];
        }
    }

    ngOnInit(): void {
        if (!this.options || this.options.length === 0) {
            this.setOptionsToSelected();
        } else {
            this.setOptionsToOptions();
        }
    }

    private setOptionsToSelected() {
        if (this.control.value) {
            this._options = [this.control.value];
        }
    }

    private setOptionsToOptions() {
        this._options = this.options;
        if (typeof this.control.value == 'object' && this.control.value[this.trackObjectsBy]) {
            this.control.setValue(this.options.find((m) => {
                return this.control.value[this.trackObjectsBy] == m[this.trackObjectsBy];
            }));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            if (this.options && this.options.length !== 0) {
                this.setOptionsToOptions();
            }
        }
    }
}
