<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)" [ngClass]="{scrolled: isScrolled}">
    <a routerLink="/pages" class="al-logo clearfix">
        <logo-icon></logo-icon>
    </a>
    <a href (click)="toggleMenu()" class="collapse-menu-link"><hamburger-icon></hamburger-icon></a>
    <!-- <div class="search">
        <i class="ion-ios-search-strong" ng-click="startSearch()"></i>
        <input id="searchInput" type="text" placeholder="Search for...">
    </div> -->
    <div class="user-profile clearfix">
        <!-- <ba-msg-center></ba-msg-center> -->
        <user-toolbar></user-toolbar>
    </div>
</div>
