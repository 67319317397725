<div class="dialog-fullscreen dialog-padded">
    <div class="modal-header">
        <h4 class="modal-title" translate>general.preview</h4>
    </div>
    <div class="modal-body">
        <nc-zoom-image
            [smallImage]="backend.urlTo(dialogData.part.preview)"
            [largeImage]="backend.urlTo(dialogData.part.preview)">
        </nc-zoom-image>
    </div>
    <div class="modal-footer">
        <button *ngIf="dialogData.onApprove && dialogData.part.statusCode === 4" type="button" class="btn btn-secondary" (click)="approve()" translate>general.approve</button>
        <button *ngIf="displayViewPitStopReportButton && dialogData.part.report" type="button" class="btn btn-secondary" (click)="viewPitStopReport()" translate>general.view_pit_stop_report</button>
        <button type="button" class="btn btn-secondary" (click)="dialogRef.close()" translate>general.close</button>
    </div>
</div>