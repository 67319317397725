export const PORTAL_MENU = [
    {
        path: 'pages',
        children: [
            {
                path: 'orders',
                data: {
                    menu: {
                        title: 'menu.orders',
                        icon: 'fa fa-shopping-cart',
                        roles: ['administrator', 'buyer'],
                        permissions: ['order.view'],
                    }
                }
            },
            {
                path: 'supplier-orders',
                data: {
                    menu: {
                        title: 'menu.orders',
                        icon: 'fa fa-shopping-cart',
                        roles: ['supplier'],
                        permissions: ['ownOrder.view'],
                    }
                }
            },
            {
                path: 'suppliers',
                data: {
                    menu: {
                        title: 'menu.suppliers',
                        icon: 'fa fa-truck',
                        roles: ['administrator'],
                        permissions: ['supplier.view'],
                    }
                }
            },
            {
                path: 'products',
                data: {
                    menu: {
                        title: 'menu.products',
                        icon: 'fa fa-cube',
                        roles: ['administrator', 'buyer'],
                        permissions: ['product.edit'],
                    }
                }
            },
            {
                path: 'users',
                data: {
                    menu: {
                        title: 'menu.users',
                        icon: 'fa fa-user',
                        roles: ['administrator'],
                        permissions: ['user.view'],
                    }
                }
            },
            {
                path: 'user-groups',
                data: {
                    menu: {
                        title: 'menu.user_groups',
                        icon: 'fa fa-users',
                        roles: ['administrator'],
                        permissions: ['userGroup.view'],
                    }
                }
            },
            {
                path: 'price-list',
                data: {
                    menu: {
                        title: 'menu.price_list',
                        icon: 'fa fa-usd',
                        roles: ['supplier'],
                        permissions: ['ownPriceList.view'],
                    }
                }
            },
            {
                path: 'pit-stop-profiles',
                data: {
                    menu: {
                        title: 'menu.pit_stop_profiles',
                        icon: 'fa fa-print',
                        roles: ['administrator', 'supplier', 'buyer'],
                        permissions: ['pitStopProfile.view', 'ownPitStopProfile.view'],
                    }
                }
            },
            {
                path: 'addresses',
                data: {
                    menu: {
                        title: 'menu.addresses',
                        icon: 'fa fa-address-book-o',
                        roles: ['administrator', 'buyer'],
                        permissions: ['address.view'],
                    }
                }
            },
            {
                path: 'audit-log',
                data: {
                    menu: {
                        title: 'menu.audit_log',
                        icon: 'fa fa-history',
                        roles: ['administrator'],
                    }
                }
            },
        ]
    }
];
