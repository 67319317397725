import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirmDialog.html'
})
export class ConfirmDialog {
    message: string;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) private dialogData: any,
    ) {
        if(dialogData) {
            this.message = dialogData.message;
        }
    }

    confirm() {
        this.dialogRef.close(true);
    }

    dismiss() {
        this.dialogRef.close(false);
    }
}
