import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from '@ngx-translate/core';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const translationOptions = {
    loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
    }
};

@NgModule({
    imports: [TranslateModule.forRoot(translationOptions)],
    exports: [TranslateModule],
    providers: [TranslateService]
})
export class AppTranslationModule {
    constructor(private translate: TranslateService) {
        translate.addLangs(['en-US', 'sv-SE', 'fr-FR']); //,"nb-NO","da-DK", "fi-FI"]);
        translate.setDefaultLang('en-US');

        if (navigator.language.includes('fr')) {
            translate.use('fr-FR');
            document.documentElement.setAttribute('lang', 'fr');
        } else {
            translate.use('en-US');
            document.documentElement.setAttribute('lang', 'en');
        }
    }
}
