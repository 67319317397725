import { Injectable } from '@angular/core';
import { BackendService } from "./backend.service";

type EventCallback = (payload: any) => any;

interface WebSocketMessage {
    command: string;
    payload: any;
    sessionId: string;
    senderSessionId: string;
    topic: string;
}

@Injectable()
export class WebSocketService {

    public static COMMAND_INIT: string = 'init';
    public static COMMAND_EVENT: string = 'event';
    public static COMMAND_RELOAD: string = 'reload';
    public static COMMAND_DISCONNECT: string = 'disconnect';

    private url: string = window['socketUrl'];

    private subscriptions: { topic: string, callback: EventCallback }[] = [];
    private reconnect: boolean = true;
    private reconnectAttempt: number = 0;
    private ws: WebSocket = null;
    private incrementId = 0;
    private sessionId: string = null;
    private connected: boolean = false;

    constructor(
        private backend: BackendService
    ) {
        this.connect();
    }

    /**
     * Connect to websocket
     *
     * @returns {boolean}
     */
    public connect(): boolean {
        if (this.connected) {
            return true;
        }

        this.ws = new WebSocket(this.url);
        this.ws.onopen = () => {
            this.reconnectAttempt = 0;
        };

        this.ws.onmessage = (event: MessageEvent) => {
            this.webSocketOnMessage(event);
        };
        this.ws.onclose = () => {
            this.connected = false;
            this.webSocketOnClose();
        };

        this.resubscribe();

        return true;
    }

    /**
     * When websocket connection is closed
     *
     * @todo This reconnect is not correct
     */
    private webSocketOnClose() {
        this.ws = null;
        this.sessionId = null;
        this.trigger('disconnected', null);
        if (this.reconnect) {
            let attempt = this.reconnectAttempt++;
            if (attempt === 0) {
                this.connect();
            } else {
                setTimeout(() => this.connect(), 10000);
            }
        }
    }

    /**
     * Receive a message from websocket
     *
     * @param event
     */
    private webSocketOnMessage(event: MessageEvent) {
        let messageObject = WebSocketService.transformMessage(event.data);

        if (!messageObject.command) {
            console.log("Invalid message: ", messageObject);
            return;
        }

        switch (messageObject.command) {
            case WebSocketService.COMMAND_INIT:
                this.sessionId = messageObject.sessionId;
                this.backend.senderSessionId = messageObject.sessionId;
                break;
            case WebSocketService.COMMAND_EVENT:
                this.trigger(messageObject.topic, messageObject);
                break;
            case WebSocketService.COMMAND_RELOAD:
                this.trigger('reload', null);
                break;
            case WebSocketService.COMMAND_DISCONNECT:
                this.ws.close();
                break;
            default:
                console.log("Invalid command: ", messageObject.command);
                return;
        }
    }

    /**
     * Subscribe to updates
     *
     * @param topic
     * @param callback
     * @returns {number}
     */
    public subscribe(topic: string, callback: EventCallback): number {
        let id = this.incrementId++;

        // Send subscription request to backend
        this.emit("subscribe", {
            topic: topic
        });

        // Add callback for subscriptions
        this.subscriptions[id] = {
            topic: topic,
            callback: callback
        };

        return id;
    };

    /**
     * Unsubscribe from updates
     *
     * @param id
     */
    public unsubscribe(id: number) {
        if (this.subscriptions[id]) {
            let subscription = this.subscriptions[id];

            // Delete this subscription from list
            delete this.subscriptions[id];

            // Check if this is the only remaining subscription
            let moreThanOneSubscription = false;
            for (let id of Object.keys(this.subscriptions)) {
                if (!this.subscriptions.hasOwnProperty(id)) {
                    continue;
                }

                let sub = this.subscriptions[id];
                if (sub.topic == subscription.topic) {
                    moreThanOneSubscription = true;
                    break;
                }
            }

            // If there are no more subscriptions than this we can emit an unsubscribe event
            if (!moreThanOneSubscription) {
                this.emit("unsubcribe", {
                    topic: subscription.topic
                });
            }
        }
    }

    /**
     * Transform a message from a string to an object
     *
     * @param message
     * @returns {any}
     */
    private static transformMessage(message: string): WebSocketMessage {
        return JSON.parse(message);
    }

    /**
     * Trigger all subscriptions
     *
     * @param topic
     * @param messageObject
     */
    private trigger(topic: string, messageObject?: WebSocketMessage) {
        for (let id of Object.keys(this.subscriptions)) {
            if (!this.subscriptions.hasOwnProperty(id)) {
                continue;
            }

            let sub = this.subscriptions[id];
            if (sub.topic != topic) {
                continue;
            }

            if (messageObject.senderSessionId && messageObject.senderSessionId == this.backend.senderSessionId) {
                continue; // Same window
            }

            sub.callback(messageObject ? messageObject.payload : null);
        }
    }

    /**
     * Emit a command to the server
     *
     * @param command
     * @param payload
     */
    private emit(command: string, payload: any) {
        if (this.ws && this.ws.readyState == this.ws.OPEN) {
            this.ws.send(
                JSON.stringify({
                    command: command,
                    payload: payload
                })
            );
        } else {
            // Websocket connection not open so we will try to send it again in 1 second
            setTimeout(() => {
                this.emit(command, payload);
            }, 1000);
        }
    }

    /**
     * Resubscribe to all active subscriptions
     */
    private resubscribe(): void {
        let subscribedToTopics = [];
        for (let id of Object.keys(this.subscriptions)) {
            if (!this.subscriptions.hasOwnProperty(id)) {
                continue;
            }

            let sub = this.subscriptions[id];
            if (subscribedToTopics.indexOf(sub.topic) === -1) {
                this.emit("subscribe", {
                    topic: sub.topic
                });
                subscribedToTopics.push(sub.topic);
            }
        }
    }

}
