import { AbstractControl } from '@angular/forms';

export class EmailValidator {

    public static validate(control: AbstractControl) {

        let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!control.value) {
            return null;
        }

        let errorResponse = {
            email: {
                valid: false
            }
        };

        if(control.value instanceof Array) {
            let valid = control.value.every((item: string) => {
                return EMAIL_REGEXP.test(item);
            });
            return valid ? null : errorResponse;
        } else {
            return EMAIL_REGEXP.test(control.value) ? null : errorResponse;
        }

    }

}
