<mat-form-field>
    <mat-label>{{placeholder}}</mat-label>
    <input
        matInput
        [value]="dateTimeString"
        [disabled]="disabled"
        (blur)="handleBlur($event)">
    <button
        class="calendar-button"
        type="button"
        mat-icon-button
        matSuffix
        [matMenuTriggerFor]="menu">
        <i class="fa fa-calendar-o" aria-hidden="true"></i>
    </button>
</mat-form-field>

<mat-menu #menu="matMenu" xPosition="before" class="disable-padding">
    <div (click)="handleMenuClick($event)">
        <nc-datetime-picker-sc
            #dateTimePickerSC
            [value]="dateTimeString"
            [minDate]="minDate"
            [maxDate]="maxDate"
            (onChange)="handleChangeSC($event)"
            [showWeekNumbers]="featureToggle('weekNumbersEnabled')"
         />
    </div>
</mat-menu>
