import { Component, ViewContainerRef } from '@angular/core';

import { GlobalState } from './global.state';
import { BaImageLoaderService, BaThemePreloader, BaThemeSpinner } from './core/services';
import { BaThemeConfig } from './core/core.config';
import { layoutPaths } from './core/core.constants';

/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app-root',
    template: `
        <main [ngClass]="{'menu-collapsed': isMenuCollapsed}" baThemeRun>
            <div class="additional-bg"></div>
            <router-outlet></router-outlet>
        </main>
    `
})
export class App {

    isMenuCollapsed: boolean = false;

    constructor(
        private _state: GlobalState,
        private _imageLoader: BaImageLoaderService,
        private _spinner: BaThemeSpinner,
        private viewContainerRef: ViewContainerRef,
        private themeConfig: BaThemeConfig,
    ) {
        themeConfig.config();

        this._loadImages();

        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
    }

    public ngAfterViewInit(): void {
        // hide spinner once all loaders are completed
        BaThemePreloader.load().then((values) => {
            this._spinner.hide();
        });
    }

    private _loadImages(): void {
        // register some loaders
        // BaThemePreloader.registerLoader(this._imageLoader.load(layoutPaths.images.root + 'sky-bg.jpg'));
    }

}
