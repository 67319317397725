import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../services';

@Pipe({
    name: 'role'
})
export class RolePipe implements PipeTransform {

    constructor(private auth: AuthService) {}

    transform(roles: string[] | string): Observable<any> {
        return this.auth.hasRole(roles);
    }

}
