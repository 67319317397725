import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

window['moment'] = moment;

@Pipe({
    name: 'moment'
})
export class MomentPipe implements PipeTransform {

    transform(dateTime: string, format: string = 'YYYY-MM-DD HH:mm'): string {
        if(!dateTime) {
            return dateTime;
        }
        return moment(dateTime).format(format);
    }

}
