import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'custom-text-field',
    template: `
        <mat-form-field>
            <mat-label>{{ label }}</mat-label>
            <input
                matInput
                [formControl]="control"
                (blur)="onBlur()"
            />
            <mat-error>
                <error-message [forControl]="control"></error-message>
            </mat-error>
        </mat-form-field>
    `,
})
export class CustomTextFieldComponent {
    @Input() label: string;
    @Input() control: AbstractControl;
    @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    onBlur() {
        this.blurEvent.emit();
    }
}
