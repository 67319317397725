import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalState } from '../../../global.state';
import { BackendService, AuthService } from "../../services";
import { IMarket, ISupplier } from "../../interfaces";

@Component({
    selector: 'user-toolbar',
    templateUrl: './userToolbar.html',
    styleUrls: ['./userToolbar.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserToolbar {

    public userName: string = '';
    public userRole: string = '';
    public isMenuCollapsed: boolean = false;
    public currentMarket: IMarket = null;
    public currentSupplier: ISupplier = null;
    public markets: IMarket[] = [];
    public suppliers: ISupplier[] = [];

    constructor(
        private _state: GlobalState,
        public backend: BackendService,
        private auth: AuthService,
        private router: Router,
    ) {
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });

        backend.getConfigFromCache().subscribe((config) => {
            let previousMarketId = localStorage.getItem('previousMarket') || null;
            let previousSupplierId = localStorage.getItem('previousSupplier') || null;

            this.userName = config.user.name;
            this.userRole = config.user.role;
            this.markets = config.markets;
            this.suppliers = config.suppliers;

            if(this.markets.length) {
                let current = this.markets.find((market) => previousMarketId === market.id);
                if (!current && this.markets[0]) {
                    current = this.markets[0];
                }
                this.selectMarket(current);
            }

            if(this.suppliers.length) {
                let current = this.suppliers.find((supplier) => previousSupplierId === supplier.id);
                if (!current && this.suppliers[0]) {
                    current = this.suppliers[0];
                }
                this.selectSupplier(current);
            }
        });
    }

    selectMarket(market) {
        this.currentMarket = market;
        localStorage.setItem('previousMarket', (market ? market.id : null ));
        this._state.currentMarket.next(this.currentMarket);
    }

    selectSupplier(supplier) {
        this.currentSupplier = supplier;
        localStorage.setItem('previousSupplier', (supplier ? supplier.id : null ));
        this._state.currentSupplier.next(this.currentSupplier);
    }

    logout() {
        this.auth.logout().subscribe(response => {
            this.router.navigate(['/login']);
        });
    }
}
