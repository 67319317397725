import {Component, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';

import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'ba-menu-item',
    templateUrl: './baMenuItem.html',
    styleUrls: ['./baMenuItem.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BaMenuItem {

    @Input() menuItem:any;
    @Input() child:boolean = false;

    @Output() itemHover = new EventEmitter<any>();
    @Output() toggleSubMenu = new EventEmitter<any>();

    constructor(
        private auth: AuthService
    ) {}

    public onHoverItem($event):void {
        this.itemHover.emit($event);
    }

    public onToggleSubMenu($event, item):boolean {
        $event.item = item;
        this.toggleSubMenu.emit($event);
        return false;
    }
}
