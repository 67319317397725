<div 
    #wrapper
    class="wrapper">
    <div 
        class="drop-area"
        (click)="handleClick($event)" 
        (drop)="handleDrop($event)"
        (dragenter)="handleDragEnter($event)"
        (dragleave)="handleDragLeave($event)"
        ondragover="return false;">   
    </div>
    <div 
        *ngIf="dragIsHappening"
        class="drop-area-hidden drag-is-happing"
        (drop)="handleDrop($event)"
        (dragenter)="handleDragEnter($event)"
        (dragleave)="handleDragLeave($event)"
        ondragover="return false;">   
    </div>
    <input 
        #input
        type="file" 
        (change)="handleInputChange($event)" 
        accept="{{ formats }}" />
    <i aria-hidden="true" [ngClass]="icon"></i>
    <label *ngIf="!file" translate>{{dragIsHappening ? 'general.drop_to_select' : 'general.drop_or_click_to_select'}}</label>
    <span class="selected" *ngIf="file">{{ file?.name }}</span>
</div>