import {
    Component, HostBinding, Input, Output, EventEmitter, OnChanges, SimpleChanges,
    ChangeDetectionStrategy,
    ViewEncapsulation
} from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
    selector: 'expandable-card',
    templateUrl: './expandableCard.html',
    styleUrls: ['./expandableCard.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'expandable-card',
        '[@fadeAnimation]': '',
    },
    animations: [
        trigger('fadeAnimation', [
            transition(':enter', [
                style({opacity: 0}),
                animate('0.5s ease-out', style({opacity: 1}))
            ]),
            transition(':leave', [
                style({opacity: 1}),
                animate('0.5s ease-out', style({opacity: 0}))
            ])
        ]),
        trigger('slideAnimation', [
            state('in', style({height: '*'})),
            transition('* => void', [
                style({height: '*'}),
                animate('0.2s ease-out', style({height: 0}))
            ]),
            state('out', style({height: 0})),
            transition('void => *', [
                style({height: 0}),
                animate('0.2s ease-out', style({height: '*'}))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableCard implements OnChanges {

    @Input() expanded: boolean;
    @Output() expandedChange = new EventEmitter();

    @HostBinding('class.expanded') expandedClass: boolean;

    ngOnChanges(changes: SimpleChanges) {
        if(changes.expanded) {
            this.expandedClass = changes.expanded.currentValue;
        }
    }

    toggleCard() {
        this.expanded = this.expandedClass = !this.expanded;
        this.expandedChange.emit(this.expanded);
    }

}

@Component({
    selector: 'expandable-card-header',
    template: '<ng-content></ng-content>',
    host: {
        class: 'expandable-card-header',
    },
})
export class ExpandableCardHeader {}

@Component({
    selector: 'expandable-card-body',
    template: '<ng-content></ng-content>',
    host: {
        class: 'expandable-card-body',
    },
})
export class ExpandableCardBody {}
