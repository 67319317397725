<div class="loader-inner" *ngIf="loader.getItems().length">
    <div class="loader-items">
        <div class="loader-item" *ngFor="let item of loader.getItems()">
            <span *ngIf="item.isLoading()">
                <i class="fa fa-circle-o-notch fa-spin"></i>
                <span>{{ item.getMessage() }}</span>
            </span>
            <span *ngIf="item.isResolved()" class="text-success">
                <i class="fa fa-check"></i>
                <span>{{ item.getMessage() }}</span>
            </span>
            <span *ngIf="item.isRejected()" class="text-danger">
                <i class="fa fa-exclamation-triangle"></i>
                <span>{{ item.getMessage() }}</span>
            </span>
        </div>
    </div>
</div>
