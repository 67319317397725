import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { AuthService } from '../services';

@Injectable()
export class OrdersGuard  {
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate(): Observable<boolean> {
        return this.auth.hasRole('supplier').pipe(map((response: boolean) => {
            if(response === true) {
                this.router.navigate(['pages/supplier-orders']);
                return false;
            } else {
                return true;
            }
        }));
    }
}