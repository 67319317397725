import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MessageService {

    constructor(
        private snackBar: MatSnackBar,
        private translate: TranslateService,
    ) {}

    public info(message: string): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.open(message, this.translate.instant('general.close'), {
            panelClass: 'snack-bar-info',
            duration: 4000,
        });
    }

    public success(message: string): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.open(message, this.translate.instant('general.close'), {
            panelClass: 'snack-bar-success',
            duration: 4000,
        });
    }

    public warning(message: string): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.open(message, this.translate.instant('general.close'), {
            panelClass: 'snack-bar-warning',
        });
    }

    public error(message: string, defaultMessage?: string): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.open(message || defaultMessage || this.translate.instant('general.an_error_occurred'), this.translate.instant('general.close'), {
            panelClass: 'snack-bar-error',
        });
    }

    public validationError(header: string, messages: any): MatSnackBarRef<SimpleSnackBar> {
        let m = header + "\n";
        for (let key in messages) {
            if (messages.hasOwnProperty(key)) {
                m += messages[key] + " \n";
            }
        }

        return this.error(m);
    }
}
