import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../services';

@Pipe({
    name: 'permission'
})
export class PermissionPipe implements PipeTransform {

    constructor(private auth: AuthService) {}

    transform(permissions: string[]): Observable<any> {
        return this.auth.can(permissions);
    }

}
