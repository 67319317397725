import { FormGroup } from '@angular/forms';

export class EqualPasswordsValidator {

    public static validate(first, second) {

        return (formGroup: FormGroup) => {

            let firstControl = formGroup.get(first);
            let secondControl = formGroup.get(second);

            if(firstControl.value == secondControl.value){
                return null;
            } else {
                secondControl.setErrors({
                    equalPasswords: {
                        valid: false
                    }
                });
            }
        }
    }
}
