import { HttpErrorResponse } from "@angular/common/http";

export function errorResponseToString(error: HttpErrorResponse): string {
    let message = '';

    try {
        let json = <any> error.error;
        message = json.message;
    } catch (e) {
    }

    if (!message) {
        message = error.statusText || 'Unknown error';
    }

    return message;
}
