import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'alert-dialog',
    templateUrl: './alertDialog.html'
})
export class AlertDialog {

    message: string;

    constructor(
        public dialogRef: MatDialogRef<AlertDialog>,
        @Inject(MAT_DIALOG_DATA) private dialogData: any,
    ) {
        this.message = dialogData.message;
    }

}
