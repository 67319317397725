<form class="dialog-md" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title" translate>login.session_timeout</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <mat-form-field>
                <mat-label>{{'login.password' | translate}}</mat-label>
                <input matInput type="password" [formControl]="form.controls.password">
            </mat-form-field>
        </div>
    </div>
    <div class="modal-footer">
        <loader [loader]="loader" class="auth-loader"></loader>
        <button type="submit" class="btn btn-primary btn-auth" [disabled]="form.invalid || loader.isLoadingOrRejected()" translate>login.login</button>
    </div>
</form>
