import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'custom-textarea',
    template: `
        <mat-form-field>
            <mat-label>{{ label }}</mat-label>
            <textarea
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                [formControl]="control"
                (blur)="onBlur()"
            ></textarea>
            <mat-error>
                <error-message [forControl]="control"></error-message>
            </mat-error>
        </mat-form-field>
    `,
})
export class CustomTextareaComponent {
    @Input() label: string;
    @Input() control: AbstractControl;
    @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    onBlur() {
        this.blurEvent.emit();
    }
}
