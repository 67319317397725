import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../services/auth.service';
import { Loader } from "../loader/loader";

@Component({
    selector: 'login-dialog',
    templateUrl: './loginDialog.html'
})
export class LoginDialog {

    form: FormGroup;
    loader: Loader = new Loader;

    constructor(
        private formBuilder: FormBuilder,
        private auth: AuthService,
        private dialogRef: MatDialogRef<LoginDialog>,
        private translate: TranslateService,
    ) {
        this.form = formBuilder.group({
            'email': ['', Validators.compose([Validators.required])],
            'password': ['', Validators.compose([Validators.required])]
        });
        auth.getUser().subscribe((user) => {
            this.form.get('email').setValue(user.email);
        });
    }

    onSubmit(): void {
        this.translate.get('login').subscribe((translations) => {
            if(this.form.valid) {
                let item = this.loader.createItem(translations.login_pending);
                this.auth.login(this.form.value).subscribe(
                    (response) => {
                        this.dialogRef.close();
                    },
                    (error) => {
                        item.reject(error.error.errors.email);
                    }
                );
            }
        });
    }

}
