import { NgModule } from '@angular/core';

import { routing } from './pages.routing';
import { NgaModule } from '../core/nga.module';

import { Portal } from './portal.component';

@NgModule({
    imports: [
        NgaModule,
        routing
    ],
    declarations: [ Portal ]
})
export class PagesModule {
}
