import { Component, Input, DoCheck, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'error-message',
    encapsulation: ViewEncapsulation.None,
    template: '<span class="error-message" translate>{{ errorMessage }}</span>',
})
export class ErrorMessage implements DoCheck {
    @Input() forControl: FormControl;

    errorMessage: string;

    messages: {} = {
        required: 'general.required_validation_message',
        email: 'general.email_validation_message',
        fileType: 'general.file_type_validation_message',
        equalPasswords: 'general.equal_passwords_validation_message',
        invalidDateTime: 'general.invalid_date_time_validation_message',
        numBetween: 'general.num_between_validation_message',
        number: 'general.number_validation_message',
    }

    constructor(
        private translate: TranslateService,
    ) {}

    ngDoCheck():void {
        if(this.forControl.errors){
            let firstError = Object.keys(this.forControl.errors)[0];
            if(this.messages[firstError]) {
                this.translate.get(this.messages[firstError], this.forControl.errors[firstError].params).subscribe((translation) => {
                    this.errorMessage = translation;
                });
            }
        } else {
            this.errorMessage = null;
        }
    }
}
