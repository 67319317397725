import { Routes, RouterModule }  from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { Portal } from './portal.component';
import { AuthGuard, OrdersGuard } from '../core/guards';

// noinspection TypeScriptValidateTypes

// export function loadChildren(path) { return System.import(path); };

export const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'approve-visuals/:token',
        loadChildren: () => import('./approve-visuals/approve-visuals.module').then(m => m.ApproveVisualsModule)
    },
    {
        path: 'pages',
        component: Portal,
        canActivateChild: [ AuthGuard ],
        children: [
            {
                path: '',
                redirectTo: 'orders',
                pathMatch: 'full'
            },
            {
                path: 'suppliers',
                loadChildren: () => import('./suppliers/suppliers.module').then(m => m.SuppliersModule)
            },
            {
                path: 'products',
                loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'user-groups',
                loadChildren: () => import('./user-groups/user-groups.module').then(m => m.UserGroupsModule)
            },
            {
                path: 'price-list',
                loadChildren: () => import('./price-list/price-list.module').then(m => m.PriceListModule)
            },
            {
                path: 'pit-stop-profiles',
                loadChildren: () => import('./pit-stop-profiles/pit-stop-profiles.module').then(m => m.PitStopProfilesModule)
            },
            {
                path: 'orders',
                loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
                canActivate: [ OrdersGuard ]
            },
            {
                path: 'supplier-orders',
                loadChildren: () => import('./supplier-orders/supplierOrders.module').then(m => m.SupplierOrdersModule)
            },
            {
                path: 'addresses',
                loadChildren: () => import('./addresses/addresses.module').then(m => m.AddressesModule)
            },
            {
                path: 'audit-log',
                loadChildren: () => import('./audit-log/audit-log.module').then(m => m.AuditLogModule)
            },
        ]
    }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
