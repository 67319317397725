<ngb-datepicker
    [showWeekNumbers]="showWeekNumbers"
    [minDate]="datePickerMinDate"
    [maxDate]="datePickerMaxDate"
    [(ngModel)]="datePickerValue">
</ngb-datepicker>
<ngb-timepicker
    [(ngModel)]="timePickerValue">
</ngb-timepicker>
<button
    class="btn btn-primary btn-small confirm-button"
    (click)="handleConfirmClick()"
    translate>
    general.ok
</button>
