import { NgModule, ApplicationRef, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing';

// App is our top level component
import { App } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './core/nga.module';
import { PagesModule } from './pages/pages.module';

// Application wide providers
const APP_PROVIDERS = [
    AppState,
    GlobalState
];

// Application providers required by Sentry
const SENTRY_PROVIDERS = [
    {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
        }),
    },
    {
        provide: Sentry.TraceService,
        deps: [Router],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
    },
]

export type StoreType = {
    state: InternalStateType,
    restoreInputValues: () => void,
    disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [App],
    declarations: [
        App
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        NgaModule.forRoot(),
        NgbModule,
        PagesModule,
        routing,
        BrowserAnimationsModule
    ],
    providers: [
        APP_PROVIDERS,
        SENTRY_PROVIDERS,
    ]
})

export class AppModule {
    constructor(
        public appRef: ApplicationRef,
        public appState: AppState
    ) {
    }
}
