import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { flatMap, map, tap, first } from 'rxjs/operators';

import { GlobalState } from '../../global.state';
import { ILoginCredentials, IAppConfig } from '../interfaces';

import { BackendService } from './backend.service';

@Injectable()
export class AuthService {

    private redirectPath: string;

    constructor(
        private backend: BackendService,
        private state: GlobalState,
    ) {}

    /**
     *
     * @returns {Observable<any>}
     */
    public getUser(): Observable<any> {
        return this.backend.getConfigFromCache().pipe(map((config: IAppConfig) => config.user));
    }

    /**
     *
     * @returns {Observable<any>}
     */
    public getPermissions(): Observable<any> {
        return this.backend.getConfigFromCache().pipe(map((config: IAppConfig) => config.permissions));
    }

    /**
     *
     * @returns {Observable<any>}
     */
    public getMarkets(): Observable<any> {
        return this.backend.getConfigFromCache().pipe(map((config: IAppConfig) => config.markets));
    }

    /**
     *
     * @returns {Observable<any>}
     */
    public getSupplier(): Observable<any> {
        return this.hasRole('supplier').pipe(flatMap((response: boolean) => {
            if(response === true) {
                return this.state.currentSupplier.pipe(first());
            } else {
                return of(undefined);
            }
        }));
    }

    /**
     *
     * @param allowedPermissions
     * @returns {Observable<boolean>}
     */
    public can(allowedPermissions: string[]): Observable<boolean> {
        return this.getPermissions().pipe(map((userPermissions: string[]) => {
            // Handle empty parameter
            if(!allowedPermissions){
                return true;
            }
            // Handle user without permissions (unauthenticated user)
            if(!userPermissions) {
                return false;
            }
            return allowedPermissions.some((allowedPermission) => {
                // Handle user with unlimited access
                if(userPermissions.indexOf('*') > -1) {
                    return true;
                }
                // Handle user with specific permission
                if(userPermissions.indexOf(allowedPermission) > -1) {
                    return true;
                }
                // Handle user with wildcard permission
                return userPermissions.some((item) => {
                    let position = item.indexOf('.*');
                    if(position > -1) {
                        let subString = item.substring(0, position + 1);
                        return (allowedPermission.indexOf(subString) > -1);
                    }
                });
            });
        }));
    }

    /**
     *
     * @param roles
     * @returns {Observable<boolean>}
     */
    public hasRole(roles: string[] | string): Observable<boolean> {
        return this.getUser().pipe(map((user: any) => {
            if(!roles){
                return true;
            }
            if(roles instanceof Array) {
                return roles.indexOf(user.role) > -1;
            } else {
                return roles == user.role;
            }
        }));
    }

    /**
     *
     * @returns {Observable<boolean>}
     */
    public isLoggedIn(): Observable<boolean> {
        return this.backend.getConfigFromCache().pipe(map((config: IAppConfig) => {
            return config.user != null;
        }));
    }

    /**
     *
     * @param credentials
     * @returns {Observable<any>}
     */
    public login(credentials: ILoginCredentials): Observable<any> {
        this.backend.resetConfig();
        return this.backend.post('login', credentials).pipe(
            map((response: any) => response.config),
            tap((config: IAppConfig) => {
                this.state.loggedInChange.next(true);
                this.backend.injectConfig(of(config));
            }),
        );
    }

    /**
     *
     * @returns {Observable<any>}
     */
    public logout(): Observable<any> {
        let observable = this.backend.post('logout').pipe(tap(() => {
            this.state.loggedInChange.next(false);
            this.backend.resetConfig()
        }));
        return observable;
    }

    /**
     *
     * @param url
     */
    public setRedirectPath(url: string): void {
        this.redirectPath = url;
    }

    /**
     *
     * @param url
     * @returns {string}
     */
    public getRedirectPath(url: string): string {
        let redirectPath = (this.redirectPath) ? this.redirectPath : url;
        this.redirectPath = undefined;
        return redirectPath;
    }
}
