import { HttpErrorResponse } from "@angular/common/http";
import { errorResponseToString } from "../../functions/errorResponseToString";
export class Loader {
    private items: LoaderItem[] = [];

    /**
     * Create a new item and adds it to the Loader.
     */
    public createItem(message: string): LoaderItem {
        let i = new LoaderItem(message, this);
        this.items.push(i);
        return i;
    };

    /**
     * Check if loader has an item that is loading.
     * @returns {boolean}
     */
    public isLoading() {
        for (let item of this.items) {
            if (item.isLoading()) {
                return true;
            }
        }
        return false;
    };

    /**
     * Check if loader has an item that is loading or rejected.
     */
    public isLoadingOrRejected(): boolean {
        for (let item of this.items) {
            if (item.isLoading() || item.isRejected()) {
                return true;
            }
        }
        return false;
    };

    /**
     * Remove an item from the list.
     */
    public removeItem(item: LoaderItem) {
        this.items.splice(this.items.indexOf(item), 1);
    };

    /**
     * Remove all items from the list.
     */
    public removeAllItems() {
        this.items = [];
    };

    /**
     * Get the current list of LoaderItems.
     */
    public getItems(): LoaderItem[] {
        return this.items;
    }
}

class LoaderItem {
    private status = 0;

    /**
     *
     */
    constructor(private message, private loader) {

    }

    /**
     * Resolve the item.
     */
    public resolve(newMessage: string, timeout: number = 1000) {
        this.message = newMessage;
        this.status = 1;
        if (timeout) {
            setTimeout(() => this.remove(), timeout);
        }
    };

    /**
     * Reject the item.
     */
    public reject(newMessage: string, timeout: number = 3000) {
        this.message = newMessage;
        this.status = 2;
        if (timeout) {
            setTimeout(() => this.remove(), timeout);
        }
    };

    /**
     * Handle an http error response and reject the item.
     */
    public rejectErrorResponse(error: HttpErrorResponse, timeout: number = 3000) {
        this.reject(errorResponseToString(error), timeout);
    };

    /**
     * Reject the item and display an error from the response.
     */
    public handleErrorResponse(response: HttpErrorResponse, message: string = null) {
        let data = <any>response.error;

        message = message || response.statusText;
        let responseMessage = data.message || data.error;

        if (responseMessage) {
            message += ': ' + responseMessage;
        }

        this.reject(message, null);

        return response;
    };

    /**
     * Update the message
     */
    public update(newMessage: string) {
        this.message = newMessage;
    };

    /**
     * Get current message.
     */
    public getMessage(): string {
        return this.message;
    };

    /**
     * Remove the item from the loader.
     */
    public remove() {
        this.loader.removeItem(this);
    };

    /**
     * Check if item is loading
     */
    public isLoading(): boolean {
        return this.status === 0;
    };

    /**
     * Check if item is resolved
     */
    public isResolved(): boolean {
        return this.status === 1;
    };

    /**
     * Check if item is rejected
     */
    public isRejected(): boolean {
        return this.status === 2;
    };
}
