import { Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { AlertDialog } from "../components/alertDialog/alertDialog.component";

@Injectable()
export class AlertService {

    constructor(
        private dialog: MatDialog,
    ) {}

    private doAlert(message: string, themeClass: string) {
        return this.dialog.open(AlertDialog, {
            data: {
                message: message,
                themeClass: themeClass,
            },
            panelClass: themeClass,
        });
    }

    public info(message: string): MatDialogRef<AlertDialog> {
        return this.doAlert(message, 'theme-info');
    }

    public success(message: string): MatDialogRef<AlertDialog> {
        return this.doAlert(message, 'theme-success');
    }

    public warning(message: string): MatDialogRef<AlertDialog> {
        return this.doAlert(message, 'theme-warning');
    }

    public error(message: string, defaultMessage?: string): MatDialogRef<AlertDialog> {
        return this.doAlert(message, 'theme-error');
    }

}
