<mat-form-field>
    <mat-label>{{placeholder}}</mat-label>
    <input
        matInput
        [matAutocomplete]="auto"
        [formControl]="searchCtrl"
        (blur)="handleBlur($event)" />
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete">
    <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
        (onSelectionChange)="handleSelect($event)">
        {{ option.name }}
    </mat-option>
</mat-autocomplete>
<mat-chip-listbox *ngIf="selectedOptions?.length">
    <mat-chip-option color="primary" selected="true" *ngFor="let option of selectedOptions; let optionIndex = index;">
        <span>{{ option.name }}</span>
        <close-icon class="mat-remove" (click)="handleRemove(optionIndex)"></close-icon>
    </mat-chip-option>
</mat-chip-listbox>
