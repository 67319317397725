import { NgModule, ModuleWithProviders, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppTranslationModule } from '../app.translation.module';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { Angular2SmartTableModule} from "angular2-smart-table";
import { NgbPaginationModule, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatDatetimePickerModule, NgxMatDateAdapter, NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule, NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import {
    BaThemeConfig
} from './core.config';

import {
    BaThemeConfigProvider
} from './core.configProvider';

import {
    BaBackTop,
    BaCard,
    BaContentTop,
    BaMenuItem,
    BaMenu,
    BaMsgCenter,
    BaPageTop,
    BaSidebar,
    ConfirmDialog,
    ErrorMessage,
    LoaderComponent,
    LanguageSwitcher,
    ncZoomImage,
    LoginDialog,
    UserToolbar,
    ExpandableCard,
    ExpandableCardHeader,
    ExpandableCardBody,
    PreviewDialog,
    AlertDialog,
} from './components';

import {
    NcSelect,
    NcInput,
    NcTextarea,
    NcFile,
    NcSearch,
    NcMultiSearch,
    NcDateTime,
    DateTimePickerSC,
    NcSelectBox,
    NcMultiString,
    NcAutoComplete,
} from './formControls'

import { BaCardBlur } from './components/baCard/baCardBlur.directive';

import {
    BaScrollPosition,
    BaSlimScroll,
    BaThemeRun
} from './directives';

import {
    BaAppPicturePipe,
    BaKameleonPicturePipe,
    BaProfilePicturePipe,
    RolePipe,
    PermissionPipe,
    MomentPipe,
    NumberPipe,
    JoinPipe,
} from './pipes';

import {
    BackendService,
    UserService,
    UserGroupService,
    SupplierService,
    ProductService,
    PriceListService,
    PitStopProfileService,
    OrderService,
    OrderApproveVisualsService,
    MarketService,
    AddressService,
    AuditLogService,
    WebSocketService,
    AuthService,
    MessageService,
    AlertService,
    BaImageLoaderService,
    BaMenuService,
    BaThemePreloader,
    BaThemeSpinner,
} from './services';

import {
    EmailValidator,
    EqualPasswordsValidator
} from './validators';

import {
    AuthGuard,
    OrdersGuard,
} from './guards';

import {
    CloseIcon,
    LogoIcon,
    HamburgerIcon,
    ArrowIcon
} from './icons';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CustomTextareaComponent } from "./formControls/custom-textarea";
import { CustomTextFieldComponent } from "./formControls/custom-text-field";

const NGA_MODULES = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppTranslationModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    Angular2SmartTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    MatProgressBarModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    MatProgressSpinnerModule
];

const NGA_COMPONENTS = [
    BaBackTop,
    BaCard,
    BaContentTop,
    BaMenuItem,
    BaMenu,
    BaMsgCenter,
    BaPageTop,
    BaSidebar,
    ConfirmDialog,
    ErrorMessage,
    LoaderComponent,
    LanguageSwitcher,
    ncZoomImage,
    LoginDialog,
    UserToolbar,
    ExpandableCard,
    ExpandableCardHeader,
    ExpandableCardBody,
    PreviewDialog,
    AlertDialog,
    DateTimePickerSC,
    CustomTextareaComponent,
    CustomTextFieldComponent,
];

const NGA_FORM_CONTROLS = [
    NcSelect,
    NcInput,
    NcTextarea,
    NcFile,
    NcSearch,
    NcMultiSearch,
    NcDateTime,
    NcSelectBox,
    NcMultiString,
    NcAutoComplete
];

const NGA_DIRECTIVES = [
    BaScrollPosition,
    BaSlimScroll,
    BaThemeRun,
    BaCardBlur
];

const NGA_PIPES = [
    BaAppPicturePipe,
    BaKameleonPicturePipe,
    BaProfilePicturePipe,
    RolePipe,
    PermissionPipe,
    MomentPipe,
    NumberPipe,
    JoinPipe,
];

const NGA_SERVICES = [
    BackendService,
    UserService,
    UserGroupService,
    SupplierService,
    ProductService,
    PriceListService,
    PitStopProfileService,
    OrderService,
    OrderApproveVisualsService,
    MarketService,
    AddressService,
    AuditLogService,
    WebSocketService,
    AuthService,
    MessageService,
    AlertService,
    BaImageLoaderService,
    BaThemePreloader,
    BaThemeSpinner,
    BaMenuService
];

const NGA_VALIDATORS = [
    EmailValidator,
    EqualPasswordsValidator
];

const NGA_GUARDS = [
    AuthGuard,
    OrdersGuard,
];

const NGA_ICONS = [
    CloseIcon,
    LogoIcon,
    HamburgerIcon,
    ArrowIcon
];

@Injectable()
export class CustomDateAdapter extends NgxMatMomentAdapter {
    override getFirstDayOfWeek(): number {
        return 1;
    }
}

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'YYYY-MM-DD HH:mm',
    },
    display: {
        dateInput: 'YYYY-MM-DD HH:mm',
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

@NgModule({
    declarations: [
        ...NGA_PIPES,
        ...NGA_DIRECTIVES,
        ...NGA_COMPONENTS,
        ...NGA_FORM_CONTROLS,
        ...NGA_ICONS,
    ],
    imports: [
        ...NGA_MODULES,
    ],
    exports: [
        ...NGA_PIPES,
        ...NGA_DIRECTIVES,
        ...NGA_COMPONENTS,
        ...NGA_FORM_CONTROLS,
        ...NGA_ICONS,
        ...NGA_MODULES,
    ],
    providers: [
        {
            provide: NgxMatDateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS,
        },
    ],
})
export class NgaModule {
    static forRoot(): ModuleWithProviders<NgaModule> {
        return {
            ngModule: NgaModule,
            providers: [
                BaThemeConfigProvider,
                BaThemeConfig,
                ...NGA_VALIDATORS,
                ...NGA_SERVICES,
                ...NGA_GUARDS,
                {
                    provide: MAT_RIPPLE_GLOBAL_OPTIONS,
                    useValue: {
                        disabled: true
                    }
                },
                {
                    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
                    useValue: {
                        hideRequiredMarker: true,
                    }
                },
            ],
        };
    }
}

